html {
  height: 100%;
  margin: 0;
  background-color: transparent;
}

body {
  height: 100%;
  margin: 0;
  background-color: rgba(0, 94, 125, 0.06);
}

 .App {
  padding: .7em 4em;
  font-size: 1.1rem;
  height: 100vh;
  background-color: rgba(0, 94, 125, 0.03);
 }

 @font-face {
  font-family: "CiscoSansTTExtraLight";
  src: local("CiscoSansTTExtraLight"),
   url("./fonts/CiscoSansTTExtraLight.ttf") format("truetype");
  font-weight: 100;
  }

  @font-face {
    font-family: "CiscoSansTTHeavy";
    src: local("CiscoSansTTHeavy"),
     url("./fonts/CiscoSansTTHeavy.ttf") format("truetype");
    font-weight: bold;
    }
